import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import SEO from '../components/seo';
import useSiteMetadata from '../hooks/use-site-metadata';

const BlogPostTemplate = ({data, location}) => {
  const post = data.markdownRemark;
  const { siteUrl } = useSiteMetadata();
  return (
    <BlogPostContainer>
      <SEO
        title={post.frontmatter.title}
        description={post.excerpt}
        ogImage={
          (post.frontmatter.ogImage &&
            post.frontmatter.ogImage.childImageSharp.original.src) ||
          null
        }
        twitterImage={
          (post.frontmatter.twitterImage &&
            post.frontmatter.twitterImage.childImageSharp.original.src) ||
          null
        }
      />
      <Small>PUBLISHED ON {post.frontmatter.date.toUpperCase()}</Small>
      <h1
        style={{
          lineHeight: 1,
          color: 'white',
          fontSize: 32,
          letterSpacing: -1,
        }}
      >
        {post.frontmatter.title}
      </h1>

      <Content>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Content>

      <div>
        Comments?&nbsp;
        <a href={`https://twitter.com/intent/tweet?url=${encodeURI(`${siteUrl}${location.pathname}`)}&text=${encodeURI(post.frontmatter.title)}%20by%20@pauldowman`} target="blank" >Share it</a>&nbsp;
        or&nbsp;
        <a href={`https://twitter.com/intent/tweet?url=${encodeURI(`${siteUrl}${location.pathname}`)}&text=@pauldowman%20a%20comment%20on%20your%20post:%0A%0A`} target="blank" >just comment</a>.
      </div>
    </BlogPostContainer>
  );
};

export default BlogPostTemplate;

const BlogPostContainer = styled.div`
  border-bottom: 2px solid white;
  margin-top: -24px;
  padding-bottom: 40px;
`;

const Content = styled.div`
  margin-top: 40px;
  width: 66%;
  padding-right: 48px;
  @media (max-width: 875px) {
    width: 100%;
    padding-right: 0;
  }
  a {
    color: #0093ff;
  }
  .gatsby-resp-image-link {
    text-decoration: none;
    border-bottom: none;
  }
  .gatsby-resp-image-wrapper {
    margin-left: initial !important;
    max-width: initial !important;
  }
  ul {
    li {
      margin-bottom: 0;
      ul {
        margin-top: 0;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  h1 {
    font-size: 32px;
    line-height: 1.25;
    margin-bottom: 0;
  }
  h2 {
    font-size: 28px;
    line-height: 1.25;
    margin-bottom: 0;
  }
  h3 {
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 0;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 0;
  }
  h5 {
    font-size: 16px;
    margin-bottom: 0;
  }
  code {
    background-color: black !important;
    padding: 0px 8px !important;
    border: 1px solid #777;
  }
  blockquote {
    border-left: 0.1rem solid;
    margin-left 1rem;
    p {
      margin-left: 1rem;
      margin-right: 2rem;
    }
  }

  .gatsby-highlight {
    code {
      border: none;
      padding: 0 !important;
    }
    border: 1px solid #777;
    background-color: black;
    border-radius: 8px;
    padding: 16px;
    overflow: auto;
    padding-bottom: 0;
    margin-bottom: 24px;
    pre {
      background: transparent;
      margin-top: 0;
      margin-bottom: 16px;
    }
  }
  @media (max-width: 680px) {
    margin-left: 0;
  }
`;

const Small = styled.div`
  font-size: 10px;
  opacity: 0.66;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Inter, sans-serif;
  margin-bottom: 8px;
`;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
